import React, { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button, { ButtonProps } from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

import Breadcrumbs, { BreadcrumbsProps } from '../breadcrumbs/Breadcrumbs';

export interface MainContainerProps {
  title: string;
  buttons?: {
    buttonProps?: ButtonProps<any, any>;
    name: string;
    path: string;
  }[];
  crumbs?: BreadcrumbsProps['crumbs'];
}

const useStyle = makeStyles((theme) => ({
  root: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  headerWrapper: {
    display: 'flex',
    alignContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  actions: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  breadcrumb: {
    marginBottom: theme.spacing(2),
  },
}));

const MainContent: FC<MainContainerProps> = ({ children, title, buttons = [], crumbs = [] }) => {
  const location = useLocation();
  const classes = useStyle();

  return (
    <Container maxWidth={false} className={classes.root}>
      <div className={classes.headerWrapper}>
        <Typography className={classes.title} variant="h4" component="h2">
          {title}
        </Typography>
        {!!buttons.length && (
          <div className={classes.actions}>
            {buttons.map(({ name, path, buttonProps = {} }) => (
              <Button
                key={name}
                to={path}
                component={RouterLink}
                color={path === location.pathname ? 'primary' : 'default'}
                variant="contained"
                {...buttonProps}
              >
                {name}
              </Button>
            ))}
          </div>
        )}
      </div>
      {!!crumbs.length && <Breadcrumbs className={classes.breadcrumb} crumbs={crumbs} />}
      {children}
    </Container>
  );
};
export default MainContent;
