import React, { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import MuiBreadcrumbs, {
  BreadcrumbsProps as MuiBreadcrumbsProps,
} from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

export interface BreadcrumbsProps extends MuiBreadcrumbsProps {
  /**
   * Breadcrumbs links
   */
  crumbs?: {
    name: string;
    path: string;
  }[];
}

const Breadcrumbs: FC<BreadcrumbsProps> = ({ crumbs = [], ...props }) => (
  <MuiBreadcrumbs aria-label="breadcrumb" {...props}>
    <Link component={RouterLink} to="/">
      Home
    </Link>
    {crumbs.map(({ name, path }) =>
      path ? (
        <Link key={name} component={RouterLink} to={path}>
          {name}
        </Link>
      ) : (
        <Typography key={name}>{name}</Typography>
      )
    )}
  </MuiBreadcrumbs>
);

export default Breadcrumbs;
