import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { makeStyles, createStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

export interface ListMenuProps {
  items: {
    path: string;
    name: string;
    children?: ListMenuProps['items'];
  }[];
  nested?: boolean;
}

const useStyle = makeStyles((theme) =>
  createStyles({
    nested: {
      paddingLeft: theme.spacing(4),
    },
  })
);

const ListMenu: FC<ListMenuProps> = ({ items, nested }) => {
  const classes = useStyle();
  const [isOpen, setIsOpen] = useState<Record<string, boolean>>({});

  const handleClick = (key: string) => () => {
    setIsOpen({
      ...isOpen,
      [key]: !isOpen[key],
    });
  };

  return (
    <List
      className={clsx({
        [classes.nested]: nested,
      })}
    >
      {items.map(({ path, name, children }) => (
        <li key={name}>
          {children ? (
            <>
              <ListItem component="div" onClick={handleClick(path)}>
                <ListItemText primary={name} />
                {isOpen[path] ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={isOpen[path]} timeout="auto" unmountOnExit>
                <ListMenu items={children} nested />
              </Collapse>
            </>
          ) : (
            <ListItem button component={Link} to={path}>
              <ListItemText primary={name} />
            </ListItem>
          )}
        </li>
      ))}
    </List>
  );
};

export default ListMenu;
