/* eslint react/jsx-wrap-multilines: "off" */
import React, { FC } from 'react';
import clsx from 'clsx';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Drawer, { DrawerProps } from '@material-ui/core/Drawer';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardHeader from '@material-ui/core/CardHeader';

import ListMenu, { ListMenuProps } from '../list-menu/ListMenu';

export interface MenuDrawerProps {
  /**
   * Main Navigation Items
   */
  mainNavigation: ListMenuProps['items'];
  /**
   * Drawer open state
   */
  isOpen: boolean;
  /**
   * Material-ui Drawer Props
   */
  drawerProps?: DrawerProps;
  /**
   * Drawer width
   */
  drawerWidth?: number;
  /**
   * User Name to display
   */
  userName?: string;
  /**
   * Url to show logo
   */
  logoUrl?: string;
}

type MenuDrawerStyleProps = {
  drawerWidth?: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      width: ({ drawerWidth }: MenuDrawerStyleProps) => drawerWidth,
      position: 'relative',
      whiteSpace: 'nowrap',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
    },
    allignedRow: {
      padding: theme.spacing(2),
      textAlign: 'center',
      '& > *:not(:last-child)': {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
      },
    },
    logo: {
      maxWidth: 100,
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
    },
  })
);

const MenuDrawer: FC<MenuDrawerProps> = ({
  logoUrl,
  userName,
  mainNavigation,
  drawerWidth = 250,
  isOpen,
  drawerProps = {},
}) => {
  const classes = useStyles({ drawerWidth });

  return (
    <Drawer
      classes={{
        paper: clsx(
          { [classes.drawerPaper]: isOpen },
          {
            [classes.drawerPaperClose]: !isOpen,
          }
        ),
      }}
      variant="permanent"
      open={isOpen}
      {...drawerProps}
    >
      {(logoUrl || userName) && (
        <div className={classes.allignedRow}>
          {logoUrl && <img className={classes.logo} src={logoUrl} alt="Hayu" />}
          {userName && (
            <Card>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe" className={classes.avatar}>
                    {userName[0]}
                  </Avatar>
                }
                title={`Hi ${userName}`}
              />
            </Card>
          )}
        </div>
      )}
      <nav aria-label="Main Navigation">
        <ListMenu items={mainNavigation} />
      </nav>
    </Drawer>
  );
};

export default MenuDrawer;
