import React, { FC, Children } from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import MuiGrid, { GridProps as MuiGridProps } from '@material-ui/core/Grid';
import GridSortableContainer, { GridSortableContainerProps } from './GridSortableContainer';
import GridSortableElement, { GridSortableElementProps } from './GridSortableElement';

export interface GridProps {
  gridContainerProps?: MuiGridProps;
  gridItemProps?: MuiGridProps;
  sortableContainerProps?: GridSortableContainerProps;
  sortableElementProps?: GridSortableElementProps;
  isDragable?: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      flexGrow: 1,
    },
  })
);

const Grid: FC<GridProps> = ({
  children,
  isDragable,
  gridContainerProps = {},
  gridItemProps = {},
  sortableContainerProps = {},
  sortableElementProps = {},
}) => {
  const classes = useStyles();

  const containerProps: MuiGridProps = {
    container: true,
    spacing: 2,
    direction: 'row',
    justify: 'flex-start',
    alignItems: 'stretch',
    ...gridContainerProps,
  };

  const itemProps: MuiGridProps = {
    item: true,
    xs: 12,
    sm: 6,
    md: 3,
    lg: 2,
    ...gridItemProps,
  };

  const items: any[] = Children.map(children, (child, index) => ({ child, index })) || [];

  return !isDragable ? (
    <div className={classes.root}>
      <MuiGrid {...containerProps}>
        {items.map(({ child, index }) => (
          <MuiGrid key={index} {...itemProps}>
            {child}
          </MuiGrid>
        ))}
      </MuiGrid>
    </div>
  ) : (
    <div className={classes.root}>
      <GridSortableContainer axis="xy" {...sortableContainerProps}>
        <MuiGrid {...containerProps}>
          {items.map(({ child, index }) => (
            <GridSortableElement key={index} index={index} {...sortableElementProps}>
              <MuiGrid {...itemProps}>{child}</MuiGrid>
            </GridSortableElement>
          ))}
        </MuiGrid>
      </GridSortableContainer>
    </div>
  );
};

export default Grid;
