import React, { FC } from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(3, 2),
      display: 'flex',
      flexDirection: 'column',
    },
    wrapper: {
      width: '100%',
    },
    img: {
      maxWidth: '100%',
      marginTop: theme.spacing(5),
    },
  })
);

const NotFoundPage: FC<{}> = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.wrapper}>
        <Typography color="primary" variant="h1" component="h2" gutterBottom>
          OOOOPS,
        </Typography>
        <Typography variant="subtitle1">
          Something has gone wrong! Try finding what you are looking for from the main navigation or
          via the home page.
        </Typography>
        <img
          className={classes.img}
          src="https://media.giphy.com/media/3o6EQpPrHNXuvqMK08/giphy.gif"
          alt="Not Found"
        />
      </div>
    </div>
  );
};

export default NotFoundPage;
