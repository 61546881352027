export default {
  themeName: 'Hayu Theme',
  spacing: 8,
  palette: {
    primary: {
      light: '#ff6384',
      main: '#ff3c66',
      dark: '#b22a47',
      contrastText: '#ffffff',
    },
  },
};
