import React, { FC, useState } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

import Header, { HeaderProps } from '../header/Header';
import MenuDrawer, { MenuDrawerProps } from '../menu-drawer/MenuDrawer';

export interface ShellProps {
  /**
   * <AppBar /> title
   */
  appTitle: string;
  /**
   * Main navigation
   */
  mainNavigation: MenuDrawerProps['mainNavigation'];
  /**
   * App Navigation Items
   */
  appsNavigation?: HeaderProps['apps'];
  /**
   * User name to display
   */
  userName?: string;
  /**
   * User name position
   */
  userNamePosition?: 'AppBar' | 'Drawer';
  /**
   * Logo url
   */
  logoUrl?: string;
  /**
   * <Header /> props
   */
  headerProps?: HeaderProps;
  /**
   * <MenuDrawer /> props
   */
  menuDrawerProps?: MenuDrawerProps;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      height: '100vh',
      flexGrow: 1,
      overflow: 'auto',
    },
  })
);

const Shell: FC<ShellProps> = ({
  userName,
  children,
  appTitle,
  logoUrl,
  mainNavigation,
  appsNavigation,
  userNamePosition = 'Drawer',
  headerProps = {},
  menuDrawerProps = {},
}) => {
  const classes = useStyles();

  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(true);

  return (
    <div className={classes.root}>
      <Header
        apps={appsNavigation}
        userName={userNamePosition === 'AppBar' ? userName : ''}
        title={appTitle}
        isDrawerOpen={isDrawerOpen}
        onDrawerOpen={() => setIsDrawerOpen(!isDrawerOpen)}
        onSignOut={() => {}}
        {...headerProps}
      />
      <MenuDrawer
        logoUrl={logoUrl}
        userName={userNamePosition === 'Drawer' ? userName : ''}
        mainNavigation={mainNavigation}
        isOpen={isDrawerOpen}
        {...menuDrawerProps}
      />
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        {children}
      </main>
    </div>
  );
};

export default Shell;
