import React, { FC, useState, MouseEventHandler } from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar, { AppBarProps } from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import MenuIcon from '@material-ui/icons/Menu';
import Link from '@material-ui/core/Link';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';

export interface HeaderProps {
  /**
   * AppBar Title
   */
  title: string;
  /**
   * Sign out handler
   */
  onSignOut?: MouseEventHandler<HTMLButtonElement | undefined>;
  /**
   * Menu button click event
   */
  onDrawerOpen?: MouseEventHandler<HTMLButtonElement | undefined>;
  /**
   * User Name to display
   */
  userName?: string;
  /**
   * Awailable apps in menu
   */
  apps?: {
    name: string;
    path: string;
  }[];
  /**
   * <AppBar /> Props
   */
  appBarProps?: AppBarProps;
  /**
   * Url to show logo
   */
  logoUrl?: string;
  /**
   * Drawer width
   */
  drawerWidth?: number;
  /**
   * Drawer open state
   */
  isDrawerOpen?: boolean;
}

type HeaderSyleProps = {
  drawerWidth: number;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    drawerOpen: {
      width: ({ drawerWidth }: HeaderSyleProps) => `calc(100% - ${drawerWidth}px)`,
      marginLeft: ({ drawerWidth }: HeaderSyleProps) => drawerWidth,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    grow: {
      flexGrow: 1,
    },
    toolbar: {
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
  })
);

const Header: FC<HeaderProps> = ({
  title,
  userName,
  logoUrl,
  onSignOut,
  onDrawerOpen,
  isDrawerOpen = false,
  drawerWidth = 250,
  appBarProps = {},
  apps = [],
}) => {
  const classes = useStyles({ drawerWidth });
  const [appMenuAnchorEl, setAppMenuAnchorEl] = useState<null | HTMLElement>(null);

  const isAppMenuOpen = Boolean(appMenuAnchorEl);

  const handleAppMenuClose = () => {
    setAppMenuAnchorEl(null);
  };

  const handleAppMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAppMenuAnchorEl(event.currentTarget);
  };

  const appMenuId = 'app-menu';
  const renderAppMenu = (
    <Menu
      anchorEl={appMenuAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={appMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isAppMenuOpen}
      onClose={handleAppMenuClose}
    >
      {apps.map(({ name, path }) => (
        <MenuItem>
          <Link underline="none" color="inherit" href={path}>
            {name}
          </Link>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <>
      <AppBar
        className={clsx(classes.grow, classes.root, { [classes.drawerOpen]: isDrawerOpen })}
        color="inherit"
        position="absolute"
        {...appBarProps}
      >
        <Toolbar className={classes.toolbar}>
          {onDrawerOpen && (
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={onDrawerOpen}>
              <MenuIcon />
            </IconButton>
          )}
          {logoUrl && <img src={logoUrl} alt={`Hayu - ${title}`} />}
          {title && <Typography variant="h6">{title}</Typography>}
          <div className={classes.grow} />
          {!!apps.length && (
            <Button
              aria-controls={appMenuId}
              aria-haspopup="true"
              onClick={handleAppMenuOpen}
              color="inherit"
            >
              Apps
            </Button>
          )}
          {userName && <Typography noWrap>{`Hi ${userName}`}</Typography>}
          {onSignOut && (
            <IconButton onClick={onSignOut} edge="end" color="inherit" aria-label="Logout">
              <PowerSettingsNewIcon />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      {renderAppMenu}
    </>
  );
};

export default Header;
